import * as React from 'react';
import {PageBackground} from '../PageBackground';
import {PageHeader} from '../PageHeader';
import {VSpacer} from '../VSpacer';
import {Images} from '../../images/Images';
import {HAlign, HAligner} from '../HAligner';
import {Text, TextSize, TextWeight} from '../Text';
import {PageFooter} from '../PageFooter';
import {PictureFrame} from '../PictureFrame';

// declare var Calendly:any;

export class CompanyPage3 extends React.Component<any, any>
{
    render()
    {
        return  <PageBackground>
                    <PageHeader/>
                    <VSpacer size={15}/>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginBottom:'20px'}}>
                            <div className={'col-sm-12'}>

                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Mission
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    Our mission is to build the best possible software for our customers, on time and within budget. Everything we do revolves around our customers, and we work hard to achieve this mission.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'white', border:'10px solid white',
                                                         backgroundColor: '#3F4553', padding:'30px', marginBottom: '30px'}}>
                        <div className={'row'} style={{marginBottom:'0px'}}>

                            <div className={'col-sm-12'}>
                                <Text color={'white'} isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Our Values
                                    <br/>
                                    <br/>
                                </Text>

                                <div className={'row'} style={{marginBottom:'0px'}}>
                                    <div className={'col-sm-9'}>
                                        <Text color={'white'} isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                            "&nbsp;
                                        </Text>
                                        <Text color={'white'} isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                            We know that you have lots of choices when picking an outsourced IT services provider.
                                            That's why we value working hard, hiring the best engineers possible, and having open
                                            communication with all of our customers. We genuinely value the opportunity to deliver great software
                                            and sincerely look forward to every project that we do.
                                        </Text>
                                        <Text color={'white'} isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                            &nbsp;"
                                        </Text>
                                        <br/><br/>

                                        <div style={{textAlign:'right'}}>
                                            <Text color={'#CCC'} isHighlight={false} size={TextSize.h4} weight={TextWeight.w500}>
                                                Mikalai Ilyin
                                            </Text>
                                            <br/>
                                            <Text color={'#CCC'} isHighlight={false} size={TextSize.h4} weight={TextWeight.w500}>
                                                Head of Technical Operations
                                            </Text>
                                        </div>
                                    </div>
                                    <div className={'col-sm-3'} style={{textAlign:'right'}}>
                                        <img src={Images.M_Ilyin} width={200} style={{marginTop:1, borderRadius:'0%', border:'5px solid white'}}/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginTop:'10px', marginBottom:'20px'}}>
                            <div className={'col-sm-6'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Our Staff
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>

                                    We're a team of software developers and quality assurance engineers, and  we're passionate about the future of technology.
                                    <br/><br/>
                                    Each of us has a passion for technology and we have a huge sense of camaraderie in sharing our technical knowledge within the team.

                                    <br/><br/>
                                    Everyone on our team is constantly learning new technologies, approaches, and new ways to solve problems.
                                    <br/><br/>
                                </Text>
                            </div>
                            <div className={'col-sm-6'}>
                                <PictureFrame>
                                    <img src={Images.OfficeInsideView}/>
                                </PictureFrame>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginTop:'10px',marginBottom:'20px'}}>
                            <div className={'col-sm-6'}>
                                <PictureFrame>
                                    <img src={Images.OfficeOutsideView}/>
                                </PictureFrame>
                            </div>
                            <div className={'col-sm-6'}>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    As a result, we’re always learning and helping each other grow in our professional careers, along the lines of software development, quality assurance, and project management.
                                    <br/><br/>
                                    Coupled with great cityscape views from the office, we’re excited to move the world of technology forward with our family-like atmosphere and fantastic team.
                                </Text>
                            </div>

                        </div>
                    </div>


                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginBottom:'20px'}}>
                            <div className={'col-sm-6'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Our Culture
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    We work hard and play hard, solving some of the toughest challenges in programming and software development.
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    When we’re not working, we enjoy a healthy lifestyle and especially the outdoors when the weather permits.
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    As a tightly knit group, our team outings involve active sports like kayaking, hiking and riding all terrain vehicles (ATVs).
                                </Text>
                            </div>
                            <div className={'col-sm-6'}>
                                <PictureFrame>
                                    <img src={Images.OfficeOuting}/>
                                </PictureFrame>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginTop:'20px'}}>
                            <div className={'col-sm-8'}>
                                <PictureFrame>
                                    <img src={Images.CompanyAddress}/>
                                </PictureFrame>
                            </div>
                            <div className={'col-sm-4'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Careers
                                </Text>
                                <VSpacer size={25}/>

                                <Text size={TextSize.h3} weight={TextWeight.w300} padding={'0px 0px 20px 0px'} spacing={1} lineHeight={1.3}>
                                    We're always looking for passionate engineers to join our team.
                                    <br/><br/>
                                    Email careers@ergalio.net with your resume and we'll get in touch to discuss next steps.
                                </Text>


                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-sm-12'} style={{marginTop:'5px'}}>
                                <VSpacer size={25}/>
                                <HAligner align={HAlign.Center}>
                                    <Text size={TextSize.h2} spacing={0.9}>
                                        {/*For us, building better software tools is a way of life.*/}
                                        Let's build better together.
                                    </Text>
                                </HAligner>
                                <VSpacer size={25}/>
                            </div>

                        </div>
                    </div>


                    <PageFooter />


                </PageBackground>;
    }
}

export default () => <CompanyPage3/>;






